import { graphql, useStaticQuery } from "gatsby"

export default function useFaqData() {
  const data = useStaticQuery(graphql`
    query getIndividualFaqData {
      markdownRemark(frontmatter: { url: { eq: "b2c-faq" } }) {
        id
        frontmatter {
          title
          accordion {
            content
            header
          }
          alternate_accordion {
            content
            header
          }
        }
      }
    }
  `)
  return data.markdownRemark
}
