import React from "react"
import Layout from "../../components/Layout"
import useFaqData from "../../static_queries/b2c/useFaqData"
import Accordion from "../../components/Accordion"
import Tabs from "antd/lib/tabs"
import "antd/lib/tabs/style/index.css"
import BlogList from "../../components/BlogList"
const { TabPane } = Tabs

export default function FAQ() {
  const { frontmatter } = useFaqData()

  return (
    <Layout b2c={true} title={frontmatter.title}>
      <Tabs type="card">
        <TabPane tab="FAQs" key="1">
          <Accordion accordion={frontmatter.accordion} />
        </TabPane>
        <TabPane tab="Reviews" key="2">
          <Accordion icon="+" accordion={frontmatter.alternate_accordion} />
        </TabPane>
      </Tabs>
    </Layout>
  )
}
